@import url('https://fonts.googleapis.com/css?family=Proza+Libre');

$primary: #169fc5; /* MAIN COLOR */
$secondary: #6aae44; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Proza Libre', sans-serif;
  color: $gray;
}
.navbar {
  margin-bottom: 0;
  z-index: 1030;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  position: relative;

  .navbar-nav>li>a {
    line-height:68px;
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
    }
  }
  .navbar-nav>li {
    background: $wht;
  }
  .nav .nav-divider {
    margin: 0px ;
    background: #ccc;
  }
  @media (max-width: 767px){
    height: 50px;
    .logo {
      max-height: 50px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.modal-dialog{
  width: 250px;
  text-align: center;
  margin:7em auto;
    input[type=submit]{
     padding: 15px 25px;
     width: 100%;
     color:$blk !important;
   }
 }
 .modal-header, .modal-footer{
  background: #333;
  color: #fff;
 }
 input#username{
  margin-bottom: 20px;
 }
 .modal-title {
   color:#fff
 }
 .modal-dialog .btn-default {width:100%; background: #333; border: none; color:#fff}
.hero-info{
  padding: 50px 0px;
  p {
    font-size: 1.3em;
    padding: 0 20px;
  }
}
.hero-quote {
  padding: 100px 0;
  background: #f15b2a; /* Old browsers */
  background: -moz-linear-gradient(left,  #f15b2a 0%, #8cc540 20%, #2989d8 49%, #e90e8b 78%, #eb2227 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #f15b2a 0%,#8cc540 20%,#2989d8 49%,#e90e8b 78%,#eb2227 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #f15b2a 0%,#8cc540 20%,#2989d8 49%,#e90e8b 78%,#eb2227 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f15b2a', endColorstr='#eb2227',GradientType=1 ); /* IE6-9 */
    @media(max-width: 767px){
      padding:40px 0;
    }
  h3 {
    font-size: 2em;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.2em;
    }
  }
}
.hero-process {
  padding: 60px 0;
  background-image: url('../img/img-full-1.jpg');
  min-height: 500px;
  background-color: #e2e2e2;
  @media(max-width: 767px){
    padding: 20px 0;
  }
  p {
    font-size: 1.6em;
    margin: 100px 0;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }

  }
}
.hero-contact {
  background-color: #edeff1;
  padding: 0;
  .col-md-5{
    background-color: $blk;
    padding: 100px 50px;
    color: $wht;
    @media(max-width: 767px){
      padding:40px 20px;
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: #ccc;
      font-weight: 100;
    }
  }
  .col-md-7 {
    padding: 100px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color:#999;
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $secondary;
  color: #ccc;
  a {
    text-decoration: none;
    color: $blk;
  }
}
